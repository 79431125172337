@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body, html {
  height: 100%;
}


.App {
  text-align: center;
  background: url("images/background-pic.jpg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
} 

.App-header {
 
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: bold;
  font-style: italic;
}

.Art-container {
  margin-top: -30px;
}

img.App-icon {
  border-radius: 50%;
  margin: 0 auto;
  width: 190px;
  height: 190px;
  border: 0.5rem solid #ec19cb;
 

}


.App-Name {
  text-align: center;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
}

.App-list ul {

  list-style: none;
  background-color: transparent;
  margin-top: 0em;
  cursor: pointer;
}

.List-container {
     margin-top: 0.5rem;
}

.list {
  background-color: #fff;
  margin: 20px 0 3px 0;
  width: 300px;
  height: 40px;
  text-align: center;
  border-radius: 40px;
  font-size: 0.9rem;
  
}

li, a {
  color: #232b2b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

}

a:link {
  text-decoration: none;
  
}

.list:hover {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%); 
  transform: scale(1.1);
}

.social-icons, li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
  cursor: pointer;

}

